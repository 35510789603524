<template lang="pug">
.wrapper.i-wrap
  YoutubePopup(
    v-if="ytState"
    @closeYT="closeYT"
    :ytUrl="ytUrl"
  )
  //- .section-left()
  LeftBar
  .section-mid
    .mtes-wrap(v-if="isLoading" style="height:200px;justify-content:center;")
      Spinner      
    transition(name="slideup")
      .mtes-wrap(v-if="!isLoading")
        router-link(v-if="model =='TvSeason'" :to="`/tvSeries/${mtes._id}`")
          p {{mtes.nameCH}}主頁
        .mtes-left-wrap(v-if="user && !isLoading")
          router-link(style="text-align:center;" v-if="user.adminLevel === 'admin'|| user.adminLevel === 'editor' " :to="`/admin/tvedit/${mtes._id}`") Edit Tv Show
        .mtes-top
          .mtes-top-left
            .poster(v-bind:class="{active : !imgLoaded}")
              img(v-bind:src="`${mtes.poster}`" @load="imgLoaded = true")
            //- .mtes-under-poster
              .mtes-videos(v-if="mtes.videos.length > 0")
                //- h4 Videos
                .mtes-video-wrap(v-for="video in mtes.videos")
                  .mtes-video(@click="openYT(video.link)")
                    img(v-bind:src="video.youtubeThumbnail")
                    i.mtes-play.far.fa-play-circle
              .director(v-if="directors.length > 0") 導演:
                span(v-for="(director, index) in directors")   
                  router-link(v-bind:to="`/star/${director.star._id}`")           
                    span(v-if="director.star.nameCH") {{director.star.nameCH}}
                    span(v-else) {{director.star.nameEN}}
                    span(v-if="(index + 1) < directors.length") ,
              .writer(v-if="writers.length > 0") 編劇:
                span(v-for="(writer, index) in writers")
                  router-link(v-bind:to="`/star/${writer.star._id}`")           
                    span(v-if="writer.star.nameCH") {{writer.star.nameCH}}
                    span(v-else) {{writer.star.nameEN}}              
                    span(v-if="(index + 1) < writers.length") ,
              .producer(v-if="producers.length > 0") 製片人:
                span(v-for="(producer, index) in producers")
                  router-link(v-bind:to="`/star/${producer.star._id}`")           
                    span(v-if="producer.star.nameCH") {{producer.star.nameCH}}
                    span(v-else) {{producer.star.nameEN}}
                    span(v-if="(index + 1) < producers.length") ,&nbsp;
          .mtes-top-right
            .mtes-title
              span(v-if="mtes.nameCH")
                h1 {{mtes.nameCH}} &nbsp; 
                  span( v-if="mtes.seasonNum" ) : 第{{mtes.seasonNum}}季
                  span( v-if="mtes.episodeNum") ,第{{mtes.episodeNum}}集
                  //- span(v-if="mtes.airDate") ({{mtes.airDate | luxon('yyyy')}})                  
                  FavoriteButton(
                    v-if="mtes.model =='tvSeries'"
                    :id="mtes._id"
                    :type="mtes.model"
                  )
                  //- IssueButton( 
                  //-   :id="mtes._id"
                  //-   :type="mtes.model"
                  //-   :nameCH="mtes.nameCH"
                  //-   :nameEN="mtes.nameEN"
                  //- )
                h4 {{mtes.nameEN}}
              span(v-else)
                h1 {{mtes.nameEN}} &nbsp;
                  //- span(v-if="mtes.releaseDateTW") ({{mtes.releaseDateTW | luxon('yyyy')}})
                  //- span(v-else-if="mtes.releaseDateUS") ({{mtes.releaseDateUS | luxon('yyyy')}}) 
                  //- IssueButton( 
                  //-   :id="mtes._id"
                  //-   :type="mtes.model"
                  //-   :nameCH="mtes.nameCH"
                  //-   :nameEN="mtes.nameEN"
                  //- )
                  FavoriteButton(
                    :id="mtes._id"
                    :type="mtes.model"
                  )
            .mtes-info
              mtesDetails(
                :genres="mtes.genresCH"
                :genresEN="mtes.genresEN"
                :model="model"
                :airDate="mtes.airDate"
              )
            .scoreSummaryWrap(v-if="model == 'TvSeason' || model == 'TvSeries' ")
              .scoreSummary
                .critic-average-wrap
                  .criticAveScore
                    .score(v-if="mtes.criticScoreAverage") {{mtes.criticScoreAverage}}
                    .score(v-else style="margin-bottom:9px") -
                  span 
                    img(src="@/assets/img/icons/feather-small.png")
                    |{{criticReviewsCount}} 位影評
                .user-average-wrap
                  .userAveScore
                    .score(v-if="mtes.userScoreAverage") {{mtes.userScoreAverage}}
                    .score(v-else style="margin-bottom:9px") -
                  span 
                    img(src="@/assets/img/icons/text-bubble-small.png")
                    |{{userReviewsCount}} 位影迷
              //- .mtes-under-score
                .mtes-videos(v-if="mtes.videos.length > 0")
                  //- h4 Videos
                  .mtes-video-wrap(v-for="video in mtes.videos")
                    .mtes-video(@click="openYT(video.link)")
                      img(v-bind:src="video.youtubeThumbnail")
                      i.mtes-play.far.fa-play-circle
                .director(v-if="directors.length > 0") 導演:
                  span(v-for="(director, index) in directors")   
                    router-link(v-bind:to="`/star/${director.star._id}`")           
                      span(v-if="director.star.nameCH") {{director.star.nameCH}}
                      span(v-else) {{director.star.nameEN}}
                      span(v-if="(index + 1) < directors.length") ,
                .writer(v-if="writers.length > 0") 編劇:
                  span(v-for="(writer, index) in writers")
                    router-link(v-bind:to="`/star/${writer.star._id}`")           
                      span(v-if="writer.star.nameCH") {{writer.star.nameCH}}
                      span(v-else) {{writer.star.nameEN}}              
                      span(v-if="(index + 1) < writers.length") ,
                .producer(v-if="producers.length > 0") 製片人:
                  span(v-for="(producer, index) in producers")
                    router-link(v-bind:to="`/star/${producer.star._id}`")           
                      span(v-if="producer.star.nameCH") {{producer.star.nameCH}}
                      span(v-else) {{producer.star.nameEN}}
                      span(v-if="(index + 1) < producers.length") ,&nbsp;
            .mtes-summary
              v-clamp( autoresize :max-lines="10" style="line-height:28px;") {{mtes.summary}}
                p.pointer(
                  v-if="expanded || clamped"
                  slot="after"
                  slot-scope="{ toggle, expanded, clamped }"
                  @click="toggle"
                  style="text-align:right"
                  )   
                  |{{ expanded ? '更少' : '更多' }}
        .mtes-mid
          TvMid(            
            :id ="id"
            :mtes ="mtes"
            :model="model"
            :tvSeasons="tvSeasons"
            :tvSeason="tvSeason"
            :tvEpisodes="tvEpisodes"
            :tvEpisode="tvEpisode"
            :userReviews="userReviews"
            :criticReviews="criticReviews"
            :cast="cast"
            :crew="crew"
            :activeTab="tab"
            @updateTab="updateTab"
          )
          
          
  .section-right(v-bind:class="{active:rightTab}")
    //- .section-left-tab.pointer(@click="rightTab = !rightTab; leftTab = false")
      span
      span
      span
    //- transition(name="slideup")
      .mtes-wrap(v-if="!isLoading")
        .mtes-videos(v-if="mtes.videos.length > 1")
          h4 Videos
          .mtes-video-wrap(v-for="video in mtes.videos")
            .mtes-video(@click="openYT(video.link)")
              img(v-bind:src="video.youtubeThumbnail")
              i.mtes-play.far.fa-play-circle
        .director(v-if="directors.length > 0") 導演:
          span(v-for="(director, index) in directors")   
            router-link(v-bind:to="`/star/${director.star._id}`")           
              span(v-if="director.star.nameCH") {{director.star.nameCH}}
              span(v-else) {{director.star.nameEN}}
              span(v-if="(index + 1) < directors.length") ,
        .writer(v-if="writers.length > 0") 編劇:
          span(v-for="(writer, index) in writers")
            router-link(v-bind:to="`/star/${writer.star._id}`")           
              span(v-if="writer.star.nameCH") {{writer.star.nameCH}}
              span(v-else) {{writer.star.nameEN}}              
              span(v-if="(index + 1) < writers.length") ,
        .producer(v-if="producers.length > 0") 製片人:
          span(v-for="(producer, index) in producers")
            router-link(v-bind:to="`/star/${producer.star._id}`")           
              span(v-if="producer.star.nameCH") {{producer.star.nameCH}}
              span(v-else) {{producer.star.nameEN}}
              span(v-if="(index + 1) < producers.length") ,&nbsp;
          
</template>

<script>
import Review from '@/components/Review'
import Spinner from 'vue-simple-spinner'
import VClamp from 'vue-clamp'
import mtesDetails from '@/components/mtesDetails'
import FavoriteButton from '@/components/FavoriteButton'
import YoutubePopup from '@/components/YoutubePopup'
import TvMid from '@/components/tv/TvMid'
import IssueButton from '@/components/IssueButton'
import LeftBar from '@/components/LeftBar'
import { mapState } from "vuex";

export default {
  name: 'TvSeries',
  components:{
    LeftBar,
    Review,
    Spinner,
    VClamp,    
    mtesDetails,    
    TvMid,
    YoutubePopup,
    FavoriteButton,
    IssueButton,
  },
  computed:{
    ...mapState(['user']),
  },
  props:{
    id:String,
    activeTab:String,
    season:String,
    episode:String,
  },
  data(){
    return{
      model:null,
      mtes:null,
      tvSeasons:null,
      tvSeason:null,
      tvEpisodes:null,
      tvEpisode:null,
      cast:null,
      crew:null,
      isLoading:true,
      criticReviews:null,
      userReviews:null,
      dirWriPro:null,
      directors:null,
      writers:null,
      producers:null,
      lines: 3,
      imgLoaded:false,
      ytUrl:null,
      ytState:false,
      leftTab:false,
      rightTab:false,
      suggestionModal:false,
      expanded:true,
      jsonldInfo:{},
      metaStuff:null,
      title:null,
    }
  },
  jsonld(){
    var x = this.isLoading;
    
    // var jsonldInfo = {
  //     "@context": "http://schema.org/",
  //     "@type": "Movie",
  //     "name": '',  
  //     "description": "",
  //     "url": "",
  //     "image":"",
      
  //     "Review":[],
    // };
    let jsonldInfo = {}
    if (!x){
      // jsonldInfo.description = this.mtes.summary || ''
      // jsonldInfo.url = `https://stinkytofu.tw/${this.mtes._model}/${this.mtes._id}`
      // jsonldInfo.image = `${this.mtes.poster}`
  //     jsonldInfo.genre = this.mtes.genres
  //     jsonldInfo.directors = [];
      // jsonldInfo.actors = [];
  //     jsonldInfo.author = [];
  //     if(this.mtes.releaseDateTW) jsonldInfo.dateCreated = this.mtes.releaseDateTW;
  //     else if(this.mtes.releaseDateUS) jsonldInfo.dateCreated = this.mtes.releaseDateUS;
  //     jsonldInfo.dateModified = this.mtes.updatedAt;
  //     // ADD ALL THE DIRECTORS TO THE JSON-LD
  //     for(let i =0; i < this.directors.length; i++){
  //       let y = {
  //         "@type":"Person",
  //         "url":`https://stinkytofu.tw/star/${this.directors[i].star._id}`
  //       };
  //       if( this.directors[i].star.nameCH) y.name = this.directors[i].star.nameCH;
  //       else y.name = this.directors[i].star.nameEN;
  //       jsonldInfo.directors.push(y);

  //     }
  //     // ADD ALL THE ACTORS TO THE JSON-LD
  //     for(let i =0; i < this.cast.length; i++){
  //       let y = {
  //         "@type":"Person",
  //         "url":`https://stinkytofu.tw/star/${this.cast[i].star._id}`,
  //         "image":this.cast[i].star.poster
  //       };
  //       if( this.cast[i].star.nameCH) y.name = this.cast[i].star.nameCH;
  //       else y.name = this.cast[i].star.nameEN;
  //       jsonldInfo.actors.push(y);
  //     }
  //     // ADD ALL THE WRITERS TO THE JSON-LD
  //     for(let i =0; i < this.writers.length; i++){
  //       let y = {
  //         "@type":"Person",
  //         "url":`https://stinkytofu.tw/star/${this.writers[i].star._id}`,
  //         "image":this.writers[i].star.poster
  //       };
  //       if( this.writers[i].star.nameCH) y.name = this.writers[i].star.nameCH;
  //       else y.name = this.writers[i].star.nameEN;
  //       jsonldInfo.author.push(y);
  //     }
      
  //     if(this.criticReviewsCount > 0){ 
  //       jsonldInfo.aggregateRating = {
  //         "@type":"AggregateRating",
  //         "description":"",
  //         "bestRating":"100",
  //         "worstRating": "0",
  //       },
  //       jsonldInfo.aggregateRating.name = "臭豆腐位影評"
  //       jsonldInfo.aggregateRating.ratingValue = `${this.mtes.criticScoreAverage}`
  //       jsonldInfo.aggregateRating.ratingCount = `${this.criticReviewsCount}`
  //       for(let i = 0; i < this.criticReviews.length; i++){
  //         let y = {
  //           "@type":"Review",
  //           "reviewBody":this.criticReviews[i].content,
  //           "reviewRating":{
  //             "@type":"Rating",
  //             "bestRating":'5',
  //             "worstRating":'0',
  //             "ratingValue":this.criticReviews[i].score.toString(),
  //           },            
  //           "dateCreated":this.criticReviews[i].createdAt,
  //           "author":{
  //             "@type":"Person",
  //             "name":this.criticReviews[i].user.displayName,
  //             "url":`https://stinkytofu.tw/profile/${this.criticReviews[i].user._id}`
  //           },
  //           "publisher":{
  //             "@type": "Organization",
  //             "name": this.criticReviews[i].user.publication
  //           }
  //         }
  //         jsonldInfo.Review.push(y)
  //       }
  //     }
  //     else if(this.userReviewsCount > 0){ 
  //       jsonldInfo.aggregateRating = {
  //       "@type":"AggregateRating",
  //       "description":"",
  //       "bestRating":"100",
  //       "worstRating": "0",
  //       },
  //       jsonldInfo.aggregateRating.name = "臭豆腐位影迷"
  //       jsonldInfo.aggregateRating.ratingValue = `${this.mtes.userScoreAverage}`
  //       jsonldInfo.aggregateRating.ratingCount = `${this.userReviewsCount}`
  //       for(let i = 0; i < this.userReviews.length; i++){
  //         let y = {
  //           "@type":"Review",
  //           "reviewBody":this.userReviews[i].content,
  //           "reviewRating":{
  //             "@type":"Rating",
  //             "bestRating":'5',
  //             "worstRating":'0',
  //             "ratingValue":this.userReviews[i].score.toString(),
  //           },
  //           "dateCreated":this.userReviews[i].createdAt,
  //           "author":{
  //             "@type":"Person",
  //             "name":this.userReviews[i].user.displayName,
  //             "url":`https://stinkytofu.tw/profile/${this.userReviews[i].user._id}`
  //           }
            
  //         }
  //         jsonldInfo.Review.push(y)
  //       }
  //     }
      
  //     if (this.mtes.nameCH){
  //       jsonldInfo.name = this.mtes.nameCH
  //     }else{
  //       jsonldInfo.name = this.mtes.nameEN
  //     }
      jsonldInfo = this.jsonldInfo
    }
    return jsonldInfo
  },
  metaInfo() {
    var x = this.isLoading;
    var theName = '';
    if (!x){
      this.metaPrep();
      // if (this.mtes.nameCH){
      //   theName = this.mtes.nameCH
      // }else{
      //   theName = this.mtes.nameEN
      // }
      // var metaStuff = [
      //   {property: 'og:title', content: theName},
      //   {property: 'og:type', content: 'video.tv_show'},
      //   {property: 'og:url', content: `https://stinkytofu.tw/movie/${this.mtes._id}`},
      //   {property: 'og:description', content: `${this.mtes.summary}` || ' ' },
      //   {property: 'og:image', content: `${this.mtes.poster}`},
      //   {property: 'twitter:image:src', content: `${this.mtes.thumbnail}`},
      //   // {property: 'og:image:width', content: '1000'},
      //   {property: 'og:locale', content: 'zh_TW'},
      //   {property: 'og:site_name', content: '臭豆腐 - 電影∣影集∣評分∣評論'},
      // ]
    }
    return {
      meta:this.isLoading ? '' : this.metaStuff,
      title: this.isLoading ? 'Loading...': this.title,
      titleTemplate: "%s - 臭豆腐 - 電影∣影集∣評分∣評論"
    }
  },
  methods:{
    async metaPrep(){
      let theName = this.mtes.nameCH ? this.mtes.nameCH : this.mtes.nameEN
      let context = "@context";
      this.jsonldInfo[context] = "http://schema.org";
      this.metaStuff=[
        {property: 'og:title', content: theName},
        {property: 'og:url', content: `https://stinkytofu.tw/${this.mtes.model}/${this.mtes._id}`},
        {property: 'og:description', content: `${this.mtes.summary}` || ' ' },
        {property: 'og:image', content: `${this.mtes.poster}`},
        {property: 'twitter:image:src', content: `${this.mtes.thumbnail}`},
        {property: 'og:locale', content: 'zh_TW'},
        {property: 'og:site_name', content: '臭豆腐 - 電影∣影集∣評分∣評論'},
      ]
      this.jsonldInfo.description = this.mtes.summary || ''
      this.jsonldInfo.url = `https://stinkytofu.tw/${this.mtes._model}/${this.mtes._id}`
      this.jsonldInfo.image = `${this.mtes.poster}`
      this.jsonldInfo.genre = this.mtes.genres
      this.jsonldInfo.actors = []
      for(let i =0; i < this.cast.length; i++){
        let y = {
          "@type":"Person",
          "url":`https://stinkytofu.tw/star/${this.cast[i].star._id}`,
          "image":this.cast[i].star.poster
        };
        if( this.cast[i].star.nameCH) y.name = this.cast[i].star.nameCH;
        else y.name = this.cast[i].star.nameEN;
        this.jsonldInfo.actors.push(y);
      }
      if(this.model == 'TvSeries') this.metaSeries();
      if(this.model == 'TvSeason') this.metaSeason();
      if(this.model == 'TvEpisode') this.metaEpisode();
    },
    async metaSeries(){
      this.metaStuff.push({property: 'og:type', content: 'video.tv_show'})
      this.title = this.mtes.nameCH ? this.mtes.nameCH : this.mtes.nameEN;
      let typ = "@type"
      this.jsonldInfo[typ] = 'TVSeries'
      this.jsonldInfo.containsSeason = []
      for(let i = 0; i < this.tvSeasons.length; i++){
        let seasonInfo = {
          "@type":	"TVSeason",
          "name":`Season ${this.tvSeasons[i].seasonNum}`,
          "url": `https://stinkytofu.tw/${this.tvSeasons[i]._id}`,
        }
        this.jsonldInfo.containsSeason.push(seasonInfo)
      }
    },
    async metaSeason(){
      let typ = "@type"
      this.jsonldInfo[typ] = 'TVSeason'
      this.title = this.mtes.nameCH ? this.mtes.nameCH : this.mtes.nameEN;
      this.title += `:第${this.mtes.seasonNum}季`
      this.jsonldInfo.season = this.mtes.seasonNum;
      this.jsonldInfo.partOfSeries = {
        "@type":"TVSeries",
        "name":this.mtes.nameCH ? this.mtes.nameCH : this.mtes.nameEN,
        "url":`https://stinkytofu.tw/tvSeries/${this.mtes._id}`
      }
    },
    async metaEpisode(){
      let typ = "@type"
      this.jsonldInfo[typ] = 'TVEpisode'
      this.title = this.mtes.nameCH ? this.mtes.nameCH : this.mtes.nameEN;
      this.title += `:第${this.mtes.seasonNum}季`
      this.jsonldInfo.season = this.mtes.seasonNum;
      this.jsonldInfo.partOfSeries = {
        "@type":"TVSeries",
        "name":this.mtes.nameCH ? this.mtes.nameCH : this.mtes.nameEN,
        "url":`https://stinkytofu.tw/tvSeries/${this.mtes._id}`
      }
      this.jsonldInfo.partOfSeason = {
        "@type":"TVSeason",
        "name":`Season ${this.mtes.seasonNum}`,
        "url":`https://stinkytofu.tw/tvSeries/${this.mtes._id}`
      }
    },
    async closeSuggestion(){
      this.suggestionModal = false;
    },
    toggleClamp(){
      this.lines > 0 ? this.lines = 0 : this.lines = 3;
      this.movieSummary = !this.movieSummary;
    },
    async updateTab(){

    },
    async closeYT(){
      this.ytState = false;
    },
    async openYT(url){
      this.ytState = true;
      this.ytUrl = url
    },
    async genreMapper(){
        var y = ['Action','Adventure','Animation','Comedy','Crime','Documentary','Drama','Family','Fantasy','History','Horror','Music','Mystery','Romance','Science Fiction','TV Movie','Thriller','War','Western'];
        var x = ['動作','冒險','動畫','喜劇','犯罪','紀錄片','劇情片','家庭','奇幻','歷史','恐怖','音樂','懸疑' ,'浪漫','科幻','電視電影','驚悚片','戰爭','西部']    
        this.mtes.genresCH = [];
        this.mtes.genresEN = [];
        for(var i=0; i < y.length; i++){
          var z = this.mtes.genres.indexOf(y[i]);
          if(z >= 0){ this.mtes.genresCH.push(x[i]); this.mtes.genresEN.push(y[i])}
        }
    },
    async tvSeriesInit(){
      try{
        var response = await this.axios.get(`/api/${this.model}/${this.id}`)
        if (response.data.err == true) this.$router.push({path:`/404`});       

        var y = ['Action','Adventure','Animation','Comedy','Crime','Documentary','Drama','Family','Fantasy','History','Horror','Music','Mystery','Romance','Science Fiction','TV Movie','Thriller','War','Western'];
        var x = ['動作','冒險','動畫','喜劇','犯罪','紀錄片','劇情片','家庭','奇幻','歷史','恐怖','音樂','懸疑' ,'浪漫','科幻','電視電影','驚悚片','戰爭','西部']    
        
        this.mtes = response.data.tvSeries;
        this.tvSeasons = response.data.tvSeasons;

        this.cast = response.data.cast;
        // this.crew = response.data.crew;
        // this.criticReviews = response.data.criticReviews;
        // this.userReviews = response.data.userReviews;
        this.userReviewsCount = response.data.userReviewsCount
        this.criticReviewsCount = response.data.criticReviewsCount
        // this.directors = Director;
        // this.writers = Writer;
        // this.producers = Producer;
        this.mtes.genresCH = [];
        this.mtes.genresEN = [];
        this.mtes.userScoreAverage = (this.mtes.userScoreAverage * 20);
        this.mtes.criticScoreAverage = (this.mtes.criticScoreAverage * 20);
        for(var i=0; i < y.length; i++){
          var z = this.mtes.genres.indexOf(y[i]);
          if(z >= 0){ this.mtes.genresCH.push(x[i]); this.mtes.genresEN.push(y[i])}
        }
        this.tab = this.activeTab;
        this.isLoading = false;
      }catch(err){
        this.flash(err,'error')
        
      }
    },
    async tvSeasonInit(){
      try{
        // var response = await this.axios.get(`/api/${this.model}/${this.id}/${this.season}`)       
        var response = await this.axios.get(`/api/${this.model}/${this.id}`)       

        var y = ['Action','Adventure','Animation','Comedy','Crime','Documentary','Drama','Family','Fantasy','History','Horror','Music','Mystery','Romance','Science Fiction','TV Movie','Thriller','War','Western'];
        var x = ['動作','冒險','動畫','喜劇','犯罪','紀錄片','劇情片','家庭','奇幻','歷史','恐怖','音樂','懸疑' ,'浪漫','科幻','電視電影','驚悚片','戰爭','西部']    
        
        this.mtes = response.data.tvSeries;
        this.mtes.summary = response.data.tvSeason.summary
        this.mtes.model = response.data.tvSeason.model
        this.mtes.poster = response.data.tvSeason.poster
        this.mtes.thumbnail = response.data.tvSeason.thumbnail
        this.mtes.seasonNum = response.data.tvSeason.seasonNum
        this.mtes.airDate = response.data.tvSeason.airDate
        this.mtes.userScoreAverage = response.data.tvSeason.userScoreAverage
        this.mtes.criticScoreAverage = response.data.tvSeason.criticScoreAverage

        this.tvSeasons = response.data.tvSeasons;
        this.tvSeason = response.data.tvSeason;
        this.tvEpisodes = response.data.tvEpisodes;
        this.cast = response.data.cast;
        // this.crew = response.data.crew;
        this.criticReviews = response.data.criticReviews;
        this.userReviews = response.data.userReviews;
        this.userReviewsCount = response.data.userReviewsCount
        this.criticReviewsCount = response.data.criticReviewsCount
        // this.directors = Director;
        // this.writers = Writer;
        // this.producers = Producer;
        this.mtes.genresCH = [];
        this.mtes.genresEN = [];
        this.mtes.userScoreAverage = (this.mtes.userScoreAverage * 20);
        this.mtes.criticScoreAverage = (this.mtes.criticScoreAverage * 20);
        for(var i=0; i < y.length; i++){
          var z = this.mtes.genres.indexOf(y[i]);
          if(z >= 0){ this.mtes.genresCH.push(x[i]); this.mtes.genresEN.push(y[i])}
        }
        this.tab = this.activeTab;
        this.isLoading = false;
      }catch(err){
        this.flash(err,'error')
      }
    },
    async tvEpisodeInit(){
      try{
        var response = await this.axios.get(`/api/${this.model}/${this.id}`)

        var y = ['Action','Adventure','Animation','Comedy','Crime','Documentary','Drama','Family','Fantasy','History','Horror','Music','Mystery','Romance','Science Fiction','TV Movie','Thriller','War','Western'];
        var x = ['動作','冒險','動畫','喜劇','犯罪','紀錄片','劇情片','家庭','奇幻','歷史','恐怖','音樂','懸疑' ,'浪漫','科幻','電視電影','驚悚片','戰爭','西部']    
        
        this.mtes = response.data.tvEpisode;
        this.mtes.summary = response.data.tvEpisode.summary
        this.mtes.model = response.data.tvEpisode.model
        this.mtes.poster = response.data.tvEpisode.poster
        this.mtes.thumbnail = response.data.tvEpisode.thumbnail
        this.mtes.seasonNum = response.data.tvEpisode.seasonNum
        this.mtes.airDate = response.data.tvEpisode.airDate
        this.mtes.userScoreAverage = response.data.tvEpisode.userScoreAverage
        this.mtes.criticScoreAverage = response.data.tvEpisode.criticScoreAverage

        this.tvSeasons = response.data.tvSeasons;
        this.tvSeason = response.data.tvSeason;
        this.tvEpisodes = response.data.tvEpisodes;
        this.cast = response.data.cast;
        // this.crew = response.data.crew;
        this.criticReviews = response.data.criticReviews;
        this.userReviews = response.data.userReviews;
        this.userReviewsCount = response.data.userReviewsCount
        this.criticReviewsCount = response.data.criticReviewsCount
        // this.directors = Director;
        // this.writers = Writer;
        // this.producers = Producer;
        this.mtes.genres = response.data.tvSeries.genres;
        this.mtes.genresCH = [];
        this.mtes.genresEN = [];
        this.mtes.userScoreAverage = (this.mtes.userScoreAverage * 20);
        this.mtes.criticScoreAverage = (this.mtes.criticScoreAverage * 20);
        for(var i=0; i < y.length; i++){
          var z = this.mtes.genres.indexOf(y[i]);
          if(z >= 0){ this.mtes.genresCH.push(x[i]); this.mtes.genresEN.push(y[i])}
        }
        this.tab = this.activeTab;
        this.isLoading = false;
      }catch(err){
        this.flash(err,'error')
      }
    }
    
  },
  async created(){
    this.model = this.$route.name;
  
    if(this.model == 'TvSeries') await this.tvSeriesInit();
    if(this.model == 'TvSeason') await this.tvSeasonInit();
    if(this.model == 'TvEpisode') await this.tvEpisodeInit();
  }   
    
  
}
</script>
<style lang="scss" scoped>
  @keyframes gradient {
      0% {
          background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
  }
  .suggestion-modal{
    position:fixed;
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:99;
    background:rgba(0,0,0, .5);
    display:flex;
    justify-content: center;
    align-items: center;

  }
  .suggestion-bg{
    position:fixed;
    width:100%;
    height:100%;
    left:0;
    top:0;
    // z-index:99;
    // background:rgba(0,0,0, .5);
  }
  .mtes-under-score{
    display:none;
  }
  .mtes-wrap{
    display:flex;
    flex-direction: column;
  }
  .mtes-top{
    width:100%;
    max-width:1400px;
    margin:20px auto 0px auto;
    padding:0 10px;
    display:flex;
    flex-wrap: wrap;
    // color:white;
    .mtes-top-left{
      // width:40%;
      max-width:250px;
    }
    .mtes-top-right{
      max-width:calc(100% - 250px);
      display:flex;
      flex-direction: column;
      padding:0 15px;

      .mtes-title{
        width:100%;
        h1{
          font-size: 32px;
          margin-bottom: 16px;
        }
        h4{
          font-size:24px;
          font-weight:100;
        }
      }
      .mtes-info{
        margin:10px 0;
      }
      .mtes-summary{
        margin-top:20px;
      }
    }

    .castListWrap{
      padding:10px;
      background:transparent;
      border-radius:10px;
    }
    .poster{      
      transition:transform .3s;
      margin-bottom:15px;
      &.active{
        height:379px;
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: gradient 10s ease infinite;
      }
      img{
        width:100%;
      box-shadow:0 12px 19.2px -7.2px rgb(5 5 5 / 30%);
      }
    }
  }
  .mtes-mid{
    margin-top:20px;
    display:flex;
    justify-content: space-between;
    .mtes-mid-left,.mtes-mid-right{
      width:49%;
      border:1px solid #e6e6e6;
      border-radius:5px;
      padding: 20px;
      background:white;
      // box-shadow:0 12px 19.2px -7.2px rgb(5 5 5 / 20%);
    }
    .mtes-mid-left{
      .mtes-user-review-wrap{
        border-bottom:1px solid #e6e6e6;

      }
      .mtes-reviews-wrap{
        display:flex;
        flex-direction: column;
        
        .mtes-reviews-card{
          margin:20px 0;
          display:flex;
          justify-content:space-between;
        }
        .mtes-reviews-tofu-wrap{
          display:flex;
        }
        .mtes-reviews-card:nth-child(even){
          flex-direction: row-reverse;
          .mtes-reviews-tofu-wrap{
            flex-direction: row-reverse;
          }
          
        }
        .mtes-reviews-content{
          width:calc(95% - 60px);
          padding:10px;
          border:1px solid #e6e6e6;
          border-radius:5px;
        }
        .mtes-reviews-tofu{
          width:23px;
          height:23px;
          margin: 0 3px;
        }
        .mtes-reviews-info{
          width:60px;
          text-align: center;
          img{
            width:100%;
          }
          p{
            font-size:12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        
      }
    }
    .mtes-mid-right{
    }
  }
 .mtes-video{
   position:relative;
   cursor:pointer;
   &:hover{
     .mtes-play{
        font-size:28px;
        box-shadow: 0px 0px 28px 5px rgba(0,0,0, .5);
        top:calc(50% - 15px);
        right:calc(50% - 15px);
     }
   }
   .mtes-play{
     color:white;
     font-size:24px;
     padding:2px;
     position:absolute;
     top:calc(50% - 14px);
     right:calc(50% - 14px);
     background-color:rgba(0,0,0, .5);
     border-radius:90%;
     box-shadow: 0px 0px 15px 1px rgba(0,0,0, .5);
     transition:box-shadow .3s, font-size .3s, top .3s, right .3s;
   }
   img{
     width:100%;
    //  visibility:hidden;
   }
 }
.scoreSummary,.critic-average-wrap, .user-average-wrap{
  display:flex;
  align-items: center;
  // justify-content: center;
}
.critic-average-wrap, .user-average-wrap{
  margin-right:20px;
  img{
    width:15px;
    margin:0 10px;
    // margin: 10px 0 10px;
  }
}
.scoreSummaryWrap{
  width:100%;
  color:black;
  margin-top:20px;

  .criticAveScore, .userAveScore{
    width:64px;
    height:64px;
    // margin:10px 0;
    border-radius:10px;
    position:relative;
    font-size:30px;
    font-weight:700;
    
    display:flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    .outOf{
      font-size:12px;
    }
    
  }    
  .criticAveScore::after, .userAveScore::after{
    content: "";
    // background-image:url('../assets/img/awakescore1.png');
    background-color:#e6e6e6;
    border-radius:5px;
    background-size:cover;
    opacity: 0.9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
  }
}
@media screen and (max-width:1024px){
  .mtes-under-poster{
    display:none;
  }
  .mtes-under-score{
    margin-top:20px;
    display: block;
  }
  .mtes-top{
    flex-direction: column;
    align-items: center;
    .mtes-top-right{
      max-width:100%;
      text-align: center;
    }
  }
  .scoreSummary,.critic-average-wrap, .user-average-wrap{
    justify-content: center;
  }
}
  
@media screen and (max-width:425px){
  .scoreSummary{
    flex-direction:column;
  }
  .critic-average-wrap{
    margin-bottom:40px;
  }
}
  
  
</style>
