<template lang="pug">
div
  .castListWrap(v-if="items.length > 0")
    h3 {{listName}}
    div
      ul(v-if="showLess")
        //- SEASON LIST
        li(v-for="season in items.slice(0,showLessNumber)")
          //- router-link(v-if="type == 'tvSeasons'" :to="`/tvSeason/${season.tvSeries}/${season.seasonNum}`")
          router-link(:to="season.urlRoute")
            .cast
              img.castImg.active(v-if="type=='tvSeasons' " v-bind:src="`${season.thumbnail}`" @error="imageLoadError")
              img.castImg(v-if="type=='tvSeasons' " src="../../assets/img/placeholder_thumb.jpg")
              img.episode-img(v-if="type == 'tvEpisodes'"  v-bind:src="`${season.thumbnail}`" @error="imageLoadError")
              img.castImg(v-if="type=='tvEpisodes' " src="../../assets/img/placeholder_thumb.jpg")
              .castInfo
                p.nameTitle(v-if=" type == 'tvSeasons' ") 第{{season.seasonNum}}季
                p.nameTitle(v-if=" type == 'tvEpisodes' ") 第{{season.episodeNum}}集
              .tofu-score-wrap(v-if="type=='tvSeasons'")
                .tofu-score 
                  p(v-if="season.userScoreAverage") {{season.userScoreAverage}}
                  p(v-else) --
                .tofu-score 
                  p(v-if="season.criticScoreAverage") {{season.criticScoreAverage}}
                  p(v-else) --
        .listMore(v-if="items.length > showLessNumber && showLess" @click="showLess = !showLess") 
          span.pointer 更多
      ul(v-else)
        li(v-for="season in items")
          router-link(:to="`/tvSeason/${season.tvSeries}/${season.seasonNum}`")
            .cast
              img.castImg.active(v-if="type=='tvSeasons' " v-bind:src="`${season.thumbnail}`" @error="imageLoadError")
              img.castImg(v-if="type=='tvSeasons' " src="../../assets/img/placeholder_thumb.jpg")
              img.episode-img(v-if="type == 'tvEpisodes'"  v-bind:src="`${season.thumbnail}`" @error="imageLoadError")
              img.castImg(v-if="type=='tvEpisodes' " src="../../assets/img/placeholder_thumb.jpg")
              .castInfo
                p.nameTitle(v-if=" type == 'tvSeasons' ") 第{{season.seasonNum}}季
                p.nameTitle(v-if=" type == 'tvEpisodes' ") 第{{season.episodeNum}}集
              .tofu-score-wrap(v-if="type=='tvSeasons'")
                .tofu-score 
                  p(v-if="season.userScoreAverage") {{season.userScoreAverage}}
                  p(v-else) --
                .tofu-score 
                  p(v-if="season.criticScoreAverage") {{season.criticScoreAverage}}
                  p(v-else) --              
        .listMore(v-if="items.length > showLessNumber && showLess == false" @click="showLess = !showLess")
          span.pointer 較少

  .castListWrap(v-else-if="items.length == 0")
    h3 {{listName}}
    p 0 電視季


              
</template>
<script>
import VClamp from 'vue-clamp'

export default {
  name: 'TvList',
  props: {
    items:Array,
    listName:String,
    tvType:String,
    type:String,
    showLessNumber:Number,
  },
  components:{
    VClamp
  },

  data(){
    return{
      showLess:true,
    }
  },
  methods:{
    imageLoadError(event){
      event.target.classList.toggle('active')
      event.target.nextSibling.classList.toggle('active')
    }
  },
  created(){
    if (this.type=='tvSeasons'){
      this.items.forEach(item => {
        item.urlRoute = `/tvSeason/${item._id}`
      })
    }else if(this.type=='tvEpisodes'){
      this.items.forEach(item => {
        item.urlRoute = `/tvEpisode/${item._id}`
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tofu-score-wrap{
  display:flex;
}
.episode-img{
  margin-right:10px;
  &.active{
    display:none;
  }
}
.tofu-score{
  width:50px;
  height:50px;
  margin:0 5px;
  background:#e6e6e6;
  border-radius:5px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.role{
  text-align:right;
  font-size:14px;
  overflow-wrap:anywhere;
  width:50%;
}
.castListWrap{
  width:100%;
}
.listMore{
  margin-top:10px;
}
.cast{
  display: flex;
  // margin:10px 0;
  padding:5px;
  align-items: center;
  box-shadow:0px 0px 0px 0px rgba(0,0,0,.1);
  // border:solid 1px black;
  transition:transform .2s, background-color .2s, box-shadow .3s;
  .castImg{
    display:none;
    &.active{
      display:flex;
    }
    width:50px;
    height:75px;
    margin-right:15px;
    background-color:lightgrey;
  }
  .castInfo{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    .nameTitle{
      font-weight:bold;
    }
    .subTitle{
      font-size:12px;
    }
  }
}
.cast:hover{
  background:rgba(0,0,0,.05);
}
.listMore{
  display:flex;
  justify-content: flex-end;
}
.castName{
  width:50%;
}
@media screen and(max-width:768px) {
  .castName{
    width:50%;
  }
  .cast .castInfo{
    flex-wrap:wrap;
  } 
}
@media screen and (max-width:425px){
  .castName{
    width:100%;
    margin-bottom:10px;
    
  }
  .cast .castImg{
    width:60px;
    height:90px;
  }
}
</style>
